export const SET_LAST_EAN_SKU = 'SET_LASTPRODUCT_ID';
export const SET_OP_DISCLAIMER_DISPLAYED_PDV_ACTIVE =
  'SET_OP_DISCLAIMER_DISPLAYED_PDV_ACTIVE';
export const SET_OP_DISCLAIMER_DISPLAYED_PDV_NON_ACTIVE =
  'SET_OP_DISCLAIMER_DISPLAYED_PDV_NON_ACTIVE';
export const ADD_SEARCH_HISTORY = 'ADD_SEARCH_HISTORY';
export const REMOVE_SEARCH_HISTORY = 'REMOVE_SEARCH_HISTORY';
export const CLEAN_SEARCH_HISTORY = 'CLEAN_SEARCH_HISTORY';
export const SET_SMARTCONSO_DISPLAYED = 'SET_SMARTCONSO_DISPLAYED';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_RECOMMENDATIONS_POPIN_DISPLAYED =
  'SET_RECOMMENDATIONS_POPIN_DISPLAYED';
export const SET_PDV = 'SET_PDV';
